import * as React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { AsideInfo } from "../components/aside/aside"

const BasicDescription = "Served with Rice, Potato, Greek Salad & Tzatziki"

const menu = [
  {
    title: "Roast Lamb",
    id: "roastlambmain",
    category: "mains",
    order: 1,
    price: "$19.00",
    description: BasicDescription,
    image: "roast-lamb",
  },
  {
    title: "Lamb Chops",
    id: "lambchopsmain",
    category: "mains",
    order: 2,
    price: "$16.00",
    description: BasicDescription,
    image: "lamb-chops",
  },
  {
    title: "Gyro Platter (Beef or Chicken)",
    id: "gryoplattermain",
    category: "mains",
    order: 3,
    price: "$16.00",
    description: BasicDescription,
    image: "beef-gyro",
  },
  {
    title: "No Carb Platter (Beef or Chicken)",
    id: "nocarbplatter",
    category: "mains",
    order: 4,
    price: "$15.00",
    description: null,
    image: "no-carb-platter-menu",
  },
  {
    title: "Chicken Souvlaki",
    id: "chiceknsouvlakimain",
    category: "mains",
    order: 5,
    price: "$16.00",
    description: BasicDescription,
    image: "chicken-souvlaki-v2",
  },
  {
    title: "Wrap (Beef or Chicken)",
    id: "wrapbeeforchickenmain",
    category: "mains",
    order: 6,
    price: "$13.00",
    description: null,
    image: "beef-wrap-menu",
  },
  {
    title: "Beef Souvlaki",
    id: "beefsouvlakimain",
    category: "mains",
    order: 7,
    price: "$16.00",
    description: BasicDescription,
    image: "beef-souvlaki",
  },
  {
    title: "Prawn Souvlaki",
    id: "prawnmain",
    category: "mains",
    order: 8,
    price: "$15.00",
    description: BasicDescription,
    image: "prawn-souvlaki",
  },
  {
    title: "Spanakopita Platter",
    id: "spanakopitamain",
    category: "mains",
    order: 9,
    price: "$15.00",
    description: BasicDescription,
    image: "spanakopita-platter",
  },
  {
    title: "Spanakopita Appetizer",
    id: "spanakopitaappy",
    category: "mains",
    order: 10,
    price: "$9.00",
    description: null,
    image: "spanakopita-appy",
  },
  {
    title: "Calamari",
    id: "calamarimain",
    category: "mains",
    order: 11,
    price: "$17.00",
    description: BasicDescription,
    image: "calamari",
  },
  {
    title: "Baked Lasagna",
    id: "bakedlasagnamain",
    category: "mains",
    order: 12,
    price: "$15.00",
    description: "with garlic toast",
    image: "lasagna",
  },
  {
    title: "Greek Salad",
    id: "greeksaladmain",
    category: "mains",
    order: 13,
    price: "$13.00",
    description: null,
    image: "greek-salad",
  },
  {
    title: "Falafel Platter",
    id: "falafaelplatter",
    category: "mains",
    order: 14,
    price: "$16.00",
    description: BasicDescription,
    image: "falafel-platter",
  },
  {
    title: "Wild Salmon",
    id: "wildsalmonmain",
    category: "mains",
    order: 15,
    price: "$19.00",
    description: BasicDescription,
    image: "wild-salmon",
  },
  {
    title: "Chicken Strips",
    id: "chickenstripsmain",
    category: "mains",
    order: 16,
    price: "$9.00",
    description: null,
    image: "chicken-strips",
  },
  {
    title: "Chicken Caesar Salad",
    id: "chickcaesarmain",
    category: "mains",
    order: 17,
    price: "$15.00",
    description: null,
    image: "caesar-salad",
  },
  // skewer start
  {
    title: "Chicken Skewer",
    id: "chickenskewer",
    category: "skewers",
    order: 1,
    price: "$4.50",
    description: null,
  },
  {
    title: "Beef Skewer",
    id: "beefskewer",
    category: "skewers",
    order: 2,
    price: "$4.50",
    description: null,
  },
  {
    title: "Prawn Skewer",
    id: "prawnskewer",
    category: "skewers",
    order: 3,
    price: "$4.50",
    description: null,
  },
  // skewer end

  // sides start
  {
    title: "Rice",
    id: "ricesides",
    category: "sides",
    order: 1,
    price: "$8.00",
    description: null,
  },
  {
    title: "Potatoes",
    id: "potatoessides",
    category: "sides",
    order: 2,
    price: "$8.00",
    description: null,
  },
  {
    title: "Tzaziki & Pita",
    id: "tzapitasides",
    category: "sides",
    order: 3,
    price: "$8.00",
    description: null,
  },
  {
    title: "Pita",
    id: "pitasides",
    category: "sides",
    order: 4,
    price: "$2.00",
    description: null,
  },
  {
    title: "Greek Salad",
    id: "greeksaladsides",
    category: "sides",
    order: 5,
    price: "$13.00",
    description: null,
  },
  {
    title: "Chicken Caesar",
    id: "chickencaesersides",
    category: "sides",
    order: 6,
    price: "$15.00",
    description: null,
  },
  // sides end

  // family pack start
  {
    title: "Family Pack A",
    id: "fampackA",
    category: "family",
    order: 1,
    price: "$48.00",
    description:
      "Chicken, Beef, Spanakopita, Greek Salad, Rice, Potoatoes, Tzatziki & Pita",
  },
  {
    title: "Family Pack B",
    id: "fampackB",
    category: "family",
    order: 2,
    price: "$48.00",
    description:
      "Chicken, Lamp Chops, Veggie Patty, Greek Salad, Rice, Potatoes, Tzatziki & Pita",
  },
  // family pack end
]

const getCategoryItems = categoryName => {
  let items = menu.filter(item => item.category === categoryName)
  let sorted = items.sort((a, b) => {
    return a.order - b.order
  })
  return sorted
}

const mains = getCategoryItems("mains")
const skewers = getCategoryItems("skewers")
const sides = getCategoryItems("sides")
const family = getCategoryItems("family")

export default function Home({ data }) {
  const {
    allImageSharp: { nodes },
  } = data

  return (
    <Layout>
      <Seo
        title="Papa Greek Food Menu"
        description="Our Fast, Fresh Greek Food Menu"
        canonical="https://papagreek.com/our-menu/"
      />

      <div className="contentWidth grid">
        <div className="gridA">
          <h1 className="heading">Our Menu</h1>
          <h2 className="subheading ourMenuSubStyle">Mains</h2>
          {/* menu start */}
          <ul className="ourMenu_ul">
            {mains.map(mainItems => {
              return (
                <li key={mainItems.id} className="ourMenu_li">
                  <div className="ourMenu_itemGrid yesborderbottom">
                    {nodes.map(node => {
                      if (node.parent.name === mainItems.image) {
                        return (
                          <GatsbyImage
                            image={getImage(node.gatsbyImageData)}
                            alt={mainItems.title}
                            className="ourMenu_img"
                          />
                        )
                      } else {
                        return ""
                      }
                    })}
                    <div className="ourMenu_txtWrapper">
                      <h2 className="ourMenu_titles">{mainItems.title}</h2>
                      {mainItems.description ? (
                        <p className="ourMenu_desc">{mainItems.description}</p>
                      ) : (
                        <p>
                          <br />
                        </p>
                      )}
                    </div>
                    <div className="ourMenu_priceWrapper">
                      <p className="ourMenu_prices">{mainItems.price}</p>
                    </div>
                  </div>
                </li>
              )
            })}
          </ul>
          {/* sides n stuff */}

          {/* skewers start */}
          <h2 className="subheading ourMenuSubStyle">Skewers</h2>
          <ul className="ourMenu_ul">
            {skewers.map(mainItems => {
              return (
                <li key={mainItems.id} className="ourMenu_li">
                  <div className="ourMenu_itemGrid noborderbottom">
                    {nodes.map(node => {
                      if (node.parent.name === mainItems.image) {
                        return (
                          <GatsbyImage
                            image={getImage(node.gatsbyImageData)}
                            alt={mainItems.title}
                            className="ourMenu_img"
                          />
                        )
                      } else {
                        return ""
                      }
                    })}
                    <div className="ourMenu_txtWrapper">
                      <h2 className="ourMenu_titles">{mainItems.title}</h2>
                      {mainItems.description ? (
                        <p className="ourMenu_desc">{mainItems.description}</p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="ourMenu_priceWrapper">
                      <p className="ourMenu_prices">{mainItems.price}</p>
                    </div>
                  </div>
                </li>
              )
            })}
          </ul>
          {/* skewers end */}

          {/* sides start */}
          <h2 className="subheading ourMenuSubStyle">Sides</h2>
          <ul className="ourMenu_ul">
            {sides.map(mainItems => {
              return (
                <li key={mainItems.id} className="ourMenu_li">
                  <div className="ourMenu_itemGrid noborderbottom">
                    {nodes.map(node => {
                      if (node.parent.name === mainItems.image) {
                        return (
                          <GatsbyImage
                            image={getImage(node.gatsbyImageData)}
                            alt={mainItems.title}
                            className="ourMenu_img"
                          />
                        )
                      } else {
                        return ""
                      }
                    })}
                    <div className="ourMenu_txtWrapper">
                      <h2 className="ourMenu_titles">{mainItems.title}</h2>
                      {mainItems.description ? (
                        <p className="ourMenu_desc">{mainItems.description}</p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="ourMenu_priceWrapper">
                      <p className="ourMenu_prices">{mainItems.price}</p>
                    </div>
                  </div>
                </li>
              )
            })}
          </ul>

          {/* sides end */}

          {/* family start */}
          <h2 className="subheading ourMenuSubStyle">Family Packs</h2>
          <ul className="ourMenu_ul">
            {family.map(mainItems => {
              return (
                <li key={mainItems.id} className="ourMenu_li">
                  <div className="ourMenu_itemGrid noborderbottom">
                    {nodes.map(node => {
                      if (node.parent.name === mainItems.image) {
                        return (
                          <GatsbyImage
                            image={getImage(node.gatsbyImageData)}
                            alt={mainItems.title}
                            className="ourMenu_img"
                          />
                        )
                      } else {
                        return ""
                      }
                    })}
                    <div className="ourMenu_txtWrapper">
                      <h2 className="ourMenu_titles">{mainItems.title}</h2>
                      {mainItems.description ? (
                        <p className="ourMenu_desc">{mainItems.description}</p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="ourMenu_priceWrapper">
                      <p className="ourMenu_prices">{mainItems.price}</p>
                    </div>
                  </div>
                </li>
              )
            })}
          </ul>

          {/* family pack end */}

          {/* menu end */}
        </div>

        <div className="gridB">
          <AsideInfo />
        </div>
      </div>
    </Layout>
  )
}

export const OurMenuQuery = graphql`
  query fileQuery {
    allImageSharp {
      nodes {
        gatsbyImageData
        parent {
          ... on File {
            id
            name
          }
        }
      }
    }
  }
`
